// Here you can add other styles
$small: 500px;
$medium: 900px;

.react-calendar {
    width: 100% !important;
}
.cardTextUserList {
    font-size: 1em !important;
}
.colForListUser {
    padding-bottom: 1em;
}
.imageUserList {
    height: 200px;
}

// for change background color
.main {
    // background-color: lightgrey;
    background: linear-gradient(
        rgba(240, 209, 10, 0.501), 
        rgba(240, 209, 10, 0.501)
      ),
      /* your image */
      url('../../src/assets/img/image_default_grey_2000_1322.png');
    background-color: red;
}
.app-footer {
    // background-color: black;
    background-color: rgba(0, 58, 18, 255);
    border-top-color: darkgoldenrod;
    border-left: darkgoldenrod solid 1px;
}
.app-header {
    // background-color: black;
    background-color: rgba(0, 58, 18, 255);
    border-bottom-color: darkgoldenrod;
}
.breadcrumb {
    // background-color: rgba(25, 7, 141, 0.35);
    // background-color: black;
    background-color: rgba(0, 58, 18, 255);
    border-bottom-color: darkgoldenrod;
}
.sidebar {
    // background-color: black;
    background-color: rgba(0, 58, 18, 255);
}
.active {
    color: white !important;
}
.navbar-nav .nav-link {
    color: rgb(168, 168, 250);
}
.footerTextNonLink {
    color: white !important;
}
.text-value {
    font-size: 0.875rem;
}
.colForAddUser {
    padding-bottom: 1rem;
}
// penyesuaian 17 Jul 2021
.backgroundLoginCard {
    background-image: linear-gradient(#A2C523, #000000);
    // background: #747474;
    border-color: #FFD700;
}
.textCommandLoginPage {
    color: white !important;
}
.textSalamLoginPage {
    padding: 0px;
    margin: 0px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white !important;
}
.forgotPasswordLoginPage {
    color: lightblue;
}
.tombolMasukLoginPage {
    background: blue;
    border-color: white;
}
.tombolMasukLoginPage:hover {
    background: lightblue;
    border-color: white;
}
.custom-card-header {
    padding-left: 15px;
}
.custom-card-header-no-padding {
    padding-left: 0px;
}
.title-card-header {
    font-size: 16px; 
    font-weight: bold;
    padding-left: 10px;
}
.btn-facebook-custom {
    background-color: #076c00;
    border-color: #076c00;
    height: 35px;
}
.btn-facebook-custom:hover {
    background-color: #0333009b;
    border-color: #0333009b;
    // height: 39px;
}
.container-table-custom {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
}
.td-table-custom {
    vertical-align: middle !important;
}
.add_button_something {
    @media screen and (max-width: $small) {
        width: 100%;
    }
}
.bg-google-plus {
    background-color: goldenrod !important;
}
.apple-popup .mapboxgl-popup-content {
    width: 500px;
}
.button-amount {
    color: white;
    background: rgba(0, 63, 1, 0.512);
    cursor: pointer;
    border: solid 1px rgb(0, 142, 2); 
    padding: 5px; 
    border-radius: 10px; 
    padding-left: 10px; 
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
}
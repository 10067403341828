html body .app.flex-row.align-items-center {
  // height: 100vh;
  // ditambahkan background
  // background-color: black;
  background-image: linear-gradient(#2E4600, rgba(0, 58, 18, 255));
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}
